import {Component, OnInit} from '@angular/core';
import {InfobarService} from '../../_services/infobar.service';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

    constructor(private infobarService: InfobarService) {
    }

    ngOnInit(): void {
    }

    /**
     * set infobar as isTop to provide default theme color
     */
    setIsTop() {
        this.infobarService.setIsTop404(true);
    }
}
