import {Injectable} from '@angular/core';
import {ImpressumComponent} from '../pages/impressum/impressum.component';
import {ImpressumAttributes} from '../_models/impressum-attributes';
import {Page} from '../_models/page';
import {CustomHtmlComponent} from '../pages/custom-html/custom-html.component';
import {CustomHtmlAttributes} from '../_models/custom-html-attributes';
import {Subject} from 'rxjs';
import {ImagePageAttributes} from '../_models/image-page-attributes';
import {ImageGalleryComponent} from '../pages/image-gallery/image-gallery.component';

@Injectable({
    providedIn: 'root'
})
export class PageService {
    private pages: Array<Page> = [];
    private currentCustomPageRoute: Subject<string> = new Subject<string>();

    private components = {
        impressum: ImpressumComponent,
        customHtml: CustomHtmlComponent,
        datenschutz: CustomHtmlComponent,
        gallery: ImageGalleryComponent
    };

    private componentAttributes = {
        impressum: new ImpressumAttributes(),
        customHtml: new CustomHtmlAttributes(),
        datenschutz: new CustomHtmlAttributes(),
        gallery: new ImagePageAttributes()
    };

    constructor() {
    }

    /**
     * track route of custom pages
     */
    getCurrentCustomPageRoute() {
        return this.currentCustomPageRoute.asObservable();
    }

    /**
     * set current route of custom pages
     * @param route of custom page
     */
    setCurrentCustomPageRoute(route: string) {
        return this.currentCustomPageRoute.next(route);
    }

    /**
     * get a component for creating dynamically
     * @param name of the component
     */
    getComponent(name: string) {
        return this.components[name];
    }

    /**
     * get the attributes for a component
     * @param componentName to get the attributes
     */
    getComponentAttributes(componentName: string) {
        this.componentAttributes = {
            impressum: new ImpressumAttributes(),
            customHtml: new CustomHtmlAttributes(),
            datenschutz: new CustomHtmlAttributes(),
            gallery: new ImagePageAttributes()
        };

        return this.componentAttributes[componentName];
    }

    /**
     * add page to pages list
     * @param p: page item
     */
    addPage(p: Page) {
        this.pages.push(p);
    }

    /**
     * get all pages
     */
    getPages() {
        return this.pages;
    }

    /**
     * get a page by route name
     * @param route to get the page of
     */
    getPage(route: string) {
        return this.pages.filter((p) => p.navConfig.id === route)[0];
    }
}
