<div class="wow animate__animated animate__fadeInUp w-100 p-5 mh-100 row m-0 w-100" [ngClass]="{'bg-section-primary': attributes?.bgColor === 'primary',
     'bg-section-secondary': attributes?.bgColor === 'secondary'}" [id]="attributes?.navConfig?.id" #wrapper spyTarget="{{attributes?.navConfig?.id}}">

    <div class="w-100 h-25" *ngIf="attributes?.sectionHeading || attributes?.sectionSubHeading">
        <h1 *ngIf="attributes?.sectionHeading"
            class="section-title text-center">{{attributes?.sectionHeading}}</h1>
        <h5 *ngIf="attributes?.sectionSubHeading"
            class="text-muted text-center">{{attributes?.sectionSubHeading}}</h5>
    </div>

    <div class="w-100 h-50">
        <ngb-carousel #carousel [interval]="5000" [pauseOnHover]="true">
            <ng-template ngbSlide *ngFor="let img of attributes?.images; index as i">
                <div class="carousel-caption text-white carousel-caption-bg p-0">
                    <div *ngIf="img?.href; else noLinkImg">
                        <a href="{{img?.href}}" target="_blank"
                           rel="nofollow noopener noreferrer">
                            <h3>{{img?.caption}}</h3>
                        </a>
                        {{img?.subCaption}}
                    </div>

                    <ng-template #noLinkImg>
                        <h3>{{img?.caption}}</h3>
                        {{img?.caption}}
                    </ng-template>
                </div>


                <div class="picsum-img-wrapper position-relative"
                     [attr.style]="('height:' + (carouselHeight) + 'px !important;') | safe:'style'">
                    <div class="carousel-bg-bg"
                         [attr.style]="'background-image: url(' + img?.path + ')' | safe: 'style'"></div>
                    <img [src]="img.path" class="carousel-img position-absolute img-fluid img-thumbnail lozad"
                         alt="My image {{i + 1}} description">
                </div>
            </ng-template>
        </ngb-carousel>
    </div>
</div>
