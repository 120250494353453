import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import lozad from 'lozad';
import {ImageTextAttributes} from '../../_models/image-text-attributes';
import {ScrollSpyService} from 'ng-spy';

@Component({
    selector: 'image-text-section',
    templateUrl: './image-text-section.component.html',
    styleUrls: ['./image-text-section.component.scss']
})
export class ImageTextSectionComponent implements OnInit, AfterViewInit {
    attributes: ImageTextAttributes;

    constructor(private spyService: ScrollSpyService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        const observer = lozad();
        observer.observe();

        this.spyService.spy({thresholdTop: 300, thresholdBottom: 400});
    }

}
