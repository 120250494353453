import {Component, OnInit} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'cookie-consent-popup',
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {
    didAgree = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * Set cookies as agreed
     */
    doAgree() {
        this.didAgree = true;
        localStorage.setItem('acceptCookies', 'true');
    }
}
