import {Input} from '@angular/core';
import {Feature} from './feature';
import {NavLinkConfig} from './nav-link-config';

export class FeatureSectionAttributes {
    sectionHeading: string;
    sectionSubHeading: string;
    features: Array<Feature>;
    faIconSize = 5;
    bgColor: string;
    navConfig: NavLinkConfig;
}
