import {NgModule, ModuleWithProviders, InjectionToken} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ThemeService} from '../_services/theme.service';
import {ThemeDirective} from '../_directives/theme.directive';
import {THEMES, ACTIVE_THEME, ThemeOptions} from '../_themes/symbols';

@NgModule({
    imports: [CommonModule],
    providers: [ThemeService],
    declarations: [ThemeDirective],
    exports: [ThemeDirective]
})
export class ThemeModule {
    static forRoot(options: ThemeOptions): ModuleWithProviders {
        return {
            ngModule: ThemeModule,
            providers: [
                {
                    provide: THEMES,
                    useValue: options.themes
                },
                {
                    provide: ACTIVE_THEME,
                    useValue: options.active
                }
            ]
        };
    }
}
