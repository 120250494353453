import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ImagePageAttributes} from '../../_models/image-page-attributes';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {Gallery, GalleryComponent, GalleryItem, GalleryRef, ImageItem} from '@ngx-gallery/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'image-gallery',
    templateUrl: './image-gallery.component.html',
    styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() attributes: ImagePageAttributes;
    private subConfig: Subscription = new Subscription();
    images: Array<any> = [];
    currentCaption: any;
    @ViewChild(GalleryComponent) gallery: GalleryComponent;

    constructor(private http: HttpClient, private imgGallery: Gallery) {
    }

    ngOnInit(): void {
        const galleryRef: GalleryRef = this.imgGallery.ref('imgGallery');
        this.subConfig = this.http.get('assets/config.json').subscribe((conf: any) => {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < conf.customPages.length; i++) {
                if (conf.customPages[i].navConfig.pagetype === 'gallery') {
                    for (let j = 0; j < conf.customPages[i].attributes.images.length; j++) {
                        if (conf.customPages[i].attributes.images[j].path.includes('youtu')) {
                            galleryRef.addYoutube({
                                src: conf.customPages[i].attributes.images[j].path.split('v=')[1]
                            });
                        } else if (conf.customPages[i].attributes.images[j].path.includes('vimeo')) {
                            galleryRef.addIframe({
                                src: conf.customPages[i].attributes.images[j].path,
                                thumb: conf.customPages[i].attributes.images[j].thumbnail
                            });
                        } else {
                            galleryRef.addImage({
                                src: conf.customPages[i].attributes.images[j].path,
                                thumb: this.getThumbnailPath(conf.customPages[i].attributes.images[j].path),
                                caption: conf.customPages[i].attributes.images[j].caption,
                                subCaption: conf.customPages[i].attributes.images[j].subCaption,
                            });
                        }
                        this.images.push(conf.customPages[i].attributes.images[j]);

                        if (j === 0) {
                            this.currentCaption = this.images[j];
                        }
                    }
                    break;
                }
            }
        });
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {
        this.subConfig.unsubscribe();
    }

    /**
     * get the thumbnails path from full Url
     * @param path  of the image
     */
    getThumbnailPath(path: string) {
        const fileName = path.split('/').pop();
        const lastSlash = path.lastIndexOf('/');
        const beforeLastSlash = path.substring(0, lastSlash);
        return beforeLastSlash + '/thumbnails/' + fileName;
    }

    /**
     * set the caption of an imageor video
     * @param $event
     */
    setCaption($event: any) {
        this.currentCaption = this.images[$event.currIndex];
    }
}
