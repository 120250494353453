import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SectionWrapperComponent} from './sections/section-wrapper/section-wrapper.component';
import {PageManagerComponent} from './pages/page-manager/page-manager.component';
import {PageNotFoundComponent} from './pages/page-not-found/page-not-found.component';


const routes: Routes = [
    {path: '', component: SectionWrapperComponent},
    {path: 'page/:route', component: PageManagerComponent},
    {path: '404', component: PageNotFoundComponent},
    {path: '**', redirectTo: '/404'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
