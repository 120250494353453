import {Image} from './image';
import {NavLinkConfig} from './nav-link-config';

export class CarouselAttributes {
    images: Array<Image>;
    navConfig: NavLinkConfig;
    bgColor: string;
    sectionHeading: string;
    sectionSubHeading: string;
}
