import {Component, OnDestroy, OnInit} from '@angular/core';
import {InfobarService} from '../_services/infobar.service';
import {Subscription} from 'rxjs';
import {SectionService} from '../_services/section.service';
import {Section} from '../_models/section';
import {OwnerInfoService} from '../_services/owner-info.service';
import {Page} from '../_models/page';
import {PageService} from '../_services/page.service';

@Component({
    selector: 'app-infobar',
    templateUrl: './infobar.component.html',
    styleUrls: ['./infobar.component.scss']
})
export class InfobarComponent implements OnInit, OnDestroy {
    sections: Array<Section> = [];
    pages: Array<Page> = [];

    showName = false;
    showLogo = false;
    companyName: string;
    logo: string;
    navbarOpen = false;

    constructor(private infobarService: InfobarService, private sectionService: SectionService,
                private ownerInfoService: OwnerInfoService, private pageService: PageService) {

    }

    subScrollTop: Subscription = new Subscription();
    subCurrentRoute: Subscription = new Subscription();
    subCurrentSection: Subscription = new Subscription();
    isTop = true;
    currentRoute = '';
    currentSection = '';

    ngOnInit(): void {
        this.showName = this.ownerInfoService.showNameInInforbar;
        this.showLogo = this.ownerInfoService.showLogoInInforbar;
        this.companyName = this.ownerInfoService.getCompany().name;
        this.logo = this.ownerInfoService.logo;

        // track if infobar is at top 0
        this.subScrollTop = this.infobarService.getIsTop().subscribe((isTop: boolean) => {
            this.isTop = isTop;
        });

        // track current route
        this.subCurrentRoute = this.infobarService.getCurrentRoute().subscribe((route: string) => {
            this.currentRoute = route;
        });

        // track current section
        this.subCurrentSection = this.infobarService.getCurrentSection().subscribe((section: string) => {
            this.currentSection = section;
        });

        // get the sections of infobar
        this.sections = this.sectionService.getSections();

        // get the custompages of th infobar
        this.pages = this.pageService.getPages();
    }

    ngOnDestroy(): void {
        this.subScrollTop.unsubscribe();
        this.subCurrentRoute.unsubscribe();
        this.subCurrentSection.unsubscribe();
    }

    /**
     * Scrolls to section smooth
     * @param jumpTo: id of the element to jump to
     */
    scrollToSection(jumpTo: string) {
        if (this.currentRoute === '') {
            const id = jumpTo;
            const yOffset = -72;
            const element = document.getElementById(id);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
        this.infobarService.setCurrentSection(jumpTo);
        this.navbarOpen = false;
    }

    /**
     * Toggle the navbar on mobile
     */
    toggleNavbar() {
        this.navbarOpen = this.navbarOpen !== true;
    }
}
