<div class="wow animate__animated animate__fadeInUp w-100 p-5 mh-100 row m-0 w-100" [ngClass]="{'bg-section-primary': attributes?.bgColor === 'primary',
     'bg-section-secondary': attributes?.bgColor === 'secondary'}" [id]="attributes?.navConfig?.id" spyTarget="{{attributes?.navConfig?.id}}">

    <div class="w-100 h-25" *ngIf="attributes?.sectionHeading || attributes?.sectionSubHeading">
        <h1 *ngIf="attributes?.sectionHeading"
            class="section-title text-center">{{attributes?.sectionHeading}}</h1>
        <h5 *ngIf="attributes?.sectionSubHeading"
            class="text-muted text-center">{{attributes?.sectionSubHeading}}</h5>
    </div>

    <div class=" w-100 d-flex justify-content-center">
        <div class="text-center"
             [ngClass]="{'h-75': attributes?.sectionHeading || attributes?.sectionSubHeading, 'align-self-center': !attributes?.sectionHeading && !attributes?.sectionSubHeading, 'h-100': !attributes?.sectionHeading && !attributes?.sectionSubHeading}">
            <div class="container-fluid">
                <div class="row">
                    <div class="card-deck">
                        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 p-0 mx-auto"
                             *ngFor="let f of attributes?.features">
                            <div class="card mb-4 box-shadow feature-bg border-0 rounded"
                                 [attr.style]="'background-image: url(' + f?.featureBgUrl + ')' | safe: 'style'">
                                <div class="card-body d-flex align-items-end h-50 text-center card-body-header">
                                    <div class="w-100" [ngStyle]="{'color': f?.iconColor || '#fff'}">
                                        <i class="{{f?.icon}} fa-{{attributes?.faIconSize}}x mb-3"
                                           [ngStyle]="{'color': f?.iconColor || '#fff'}"></i>
                                        <p class="mb-0">{{f?.header}}</p>
                                    </div>
                                </div>
                                <div class="card-body d-flex align-items-start h-50 pt-0 card-body-content">
                                    <div class="text-white m-0 mb-4 w-100">
                                       <div [innerHTML]="f?.subtext"></div>
                                    </div>
                                </div>
                                <div class="bg-overlay rounded"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
