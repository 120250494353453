import {Input} from '@angular/core';
import {NavLinkConfig} from './nav-link-config';

export class ImageTextAttributes {
    sectionHeading: string;
    sectionSubHeading: string;
    leftColContent = 'text';
    sectionText: string;
    sectionImageSrc = 'assets/img/sections/placeholder.png';
    bgColor: string;
    navConfig: NavLinkConfig;
}
