import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {PageService} from '../../_services/page.service';
import {PageNotFoundComponent} from '../page-not-found/page-not-found.component';

@Component({
    selector: 'app-page-manager',
    templateUrl: './page-manager.component.html',
    styleUrls: ['./page-manager.component.scss']
})
export class PageManagerComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('pageContainer', {read: ViewContainerRef}) entry: ViewContainerRef;
    private subParams: Subscription = new Subscription();
    private routeParam: string;
    private routerSubscription: Subscription = new Subscription();

    constructor(private route: ActivatedRoute, private pageService: PageService,
                private resolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef) {
        this.pageService.getCurrentCustomPageRoute().subscribe((r: string) => {
            this.routeParam = r;
            try {
                this.createComponent(this.pageService.getComponent(this.pageService.getPage(this.routeParam).attributes.navConfig.pagetype),
                    this.pageService.getPage(this.routeParam).attributes);
            } catch (e) {
                this.createComponent(PageNotFoundComponent,
                    new PageNotFoundComponent(null));
            }
        });
    }

    /**
     * Create dynamically component instance with attributes
     * @param component to load
     * @param attributes of the component
     */
    createComponent(component: any, attributes: any) {
        this.entry.clear();
        const factory = this.resolver.resolveComponentFactory(component);
        const componentRef = this.entry.createComponent(factory);
        // @ts-ignore
        componentRef.instance.attributes = attributes;
        this.cdr.detectChanges();
    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subParams.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.subParams = this.route.paramMap.pipe(
            map((pMap) => {
                return pMap.get('route');
            })
        ).subscribe((route) => {
            this.routeParam = route;
            this.pageService.setCurrentCustomPageRoute(route);
        });
    }

}
