import { Theme } from './symbols';

export const defaultTheme: Theme = {
    name: 'default',
    properties: {
        '--color-primary': '#40739e',
        '--color-secondary': '#44bd32',
        '--row-primary': '#f2f2f2',
        '--row-secondary': '#ffffff'
    }
};
