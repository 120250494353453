<div class="col-12 d-flex justify-content-center my-auto background h-100 w-100" [ngStyle]="{'background-image': 'url(' + attributes?.bgUrl + ')'}" [id]="attributes?.navConfig?.id" spyTarget="{{attributes?.navConfig?.id}}">
    <div class="text-center my-auto" style="z-index: 3">
        <h1 [ngStyle]="{'color': attributes?.textColor}" class="big-teaser animate__animated {{attributes?.bigTeaserAnimation}} m-0 pr-3">{{attributes?.bigTeaser}}</h1><br>
        <h2 [ngStyle]="{'color': attributes?.subTitleTextColor}" class="small-teaser animate__animated {{attributes?.smallTeaserAnimation}} animate__delay-1s pr-3">{{attributes?.smallTeaser}}</h2>
        <a *ngIf="attributes?.showButton === true" [href]="attributes?.buttonUrl" target="_blank" class="btn btn-primary btn-lg">{{attributes?.buttonLabel}}</a>
    </div>
    <div class="theme-bg-primary">
    </div>
</div>
<div style="cursor: pointer" (click)="scrollToNextSection()" class="angle-down-landing animate__animated animate__delay-2s animate__bounce">
    <i [ngStyle]="{'color': attributes?.textColor}" class="fas fa-angle-down fa-3x"></i>
</div>
