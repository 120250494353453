import {Component, Input, OnInit} from '@angular/core';
import {FooterAttributes} from '../../_models/footer-attributes';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'footer-section',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    @Input() attributes: FooterAttributes;

    constructor() {
    }

    ngOnInit(): void {
    }

    /**
     * removes protocol from displayed string
     * @param value: full link
     */
    removeHttps(value: string) {
        return value.substr(8);
    }

}
