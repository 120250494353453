export class Address {
    street: string;
    houseNr: string;
    plz: string;
    city: string;
    country: string;
    name: string;


    constructor() {
    }

    public createInstance(obj: {}) {
        Object.assign(this, obj);
    }
}
