import {Component, Input, OnInit} from '@angular/core';
import {ImpressumAttributes} from '../../_models/impressum-attributes';

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  @Input() attributes: ImpressumAttributes;

  constructor() { }

  ngOnInit(): void {
  }

}
