import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InfobarService {
    isTop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    currentRoute: Subject<string> = new Subject<string>();
    private scrollToSubject: Subject<any> = new Subject<any>();
    currentSection: BehaviorSubject<string> = new BehaviorSubject<string>('start');

    constructor() {
    }

    /**
     * subscribable to the section where to scroll to
     */
    getScrollTo() {
        return this.scrollToSubject.asObservable();
    }

    /**
     * Set the section where to scroll
     * @param section to scroll to
     * @param offset for depth of scroll
     */
    setScrollTo(section: string, offset: number = 0) {
        this.scrollToSubject.next({section, offset});
        this.setCurrentSection(section);
    }

    /**
     * track section
     */
    public getCurrentSection() {
        return this.currentSection.asObservable();
    }

    /**
     * set currentSection
     * @param section which should be displayed
     */
    public setCurrentSection(section: string) {
        this.currentSection.next(section);
    }

    /**
     * Get the current scroll position (on top or not)
     */
    public getIsTop() {
        return this.isTop.asObservable();
    }

    /**
     * set the current scroll position (top or not)
     * @param isTop: tell if scrolling reached top
     */
    public setIsTop(isTop: boolean) {
        if (this.isTop.getValue() !== isTop) {
            this.isTop.next(isTop);
        }
    }

    /**
     * set the current scroll position is top for 404 page
     * @param isTop: tell if scrolling reached top
     */
    public setIsTop404(isTop: boolean) {
        this.isTop.next(isTop);
    }

    /**
     * use for tracking current route
     */
    public getCurrentRoute() {
        return this.currentRoute.asObservable();
    }

    /**
     * set the current route name
     * @param route which is current
     */
    public setCurrentRoute(route: string) {
        this.currentRoute.next(route);
    }
}
