import {Injectable} from '@angular/core';
import {Section} from '../_models/section';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SectionService {
    private sections: Array<Section> = [];

    constructor() {
    }

    /**
     * Get all sections of the page
     */
    getSections() {
        return this.sections;
    }

    /**
     * set all sections
     * @param sections that should be displayed
     */
    setSections(sections: Array<Section>) {
        this.sections = sections;
    }

    /**
     * add a section to existing sections at the end
     * @param section that should be added
     */
    addSection(section: Section) {
        this.sections.push(section);
    }

    /**
     * add section at wanted order
     * @param section that should be added
     * @param index where the section should be displayed
     */
    addSectionAt(section: Section, index: number) {
        this.sections[index] = section;
        this.sections.slice();
    }
}
