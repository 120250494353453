export class NavLinkConfig {
    label: string;
    id: string;
    jumpTo: string;
    pagetype: string;


    constructor(label: string, id: string, jumpTo: string, pagetype: string) {
        this.label = label;
        this.id = id;
        this.jumpTo = jumpTo;
        this.pagetype = pagetype;
    }
}
