export class Person {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    mobile: string;

    public createInstance(obj: {}) {
        Object.assign(this, obj);
    }
}
