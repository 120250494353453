import {Input} from '@angular/core';
import {Person} from './person';
import {Address} from './address';
import {NavLinkConfig} from './nav-link-config';

export class ContactFormAttributes {
    sectionHeading: string;
    sectionSubHeading: string;
    recaptchaSiteKey: string;
    bgColor: string;
    tokenVerificationUrl = 'http://localhost/verifyCaptcha.php';
    mailerUrl = 'http://localhost/mailer.php';
    contactPerson: Person;
    companyAddress: Address;
    showGoogleMaps = true;
    navConfig: NavLinkConfig;
}
