import {Address} from './address';
import {Person} from './person';

export class Company {
    name: string;
    address: Address;
    owner: Person;
    email: string;
    phone: string;
    mobile: string;
    facebook: string;
    instagram: string;
    whatsapp: string;
    twitter: string;
    twitch: string;
    fax: string;
    etsy: string;
    vimeo: string;


    constructor() {
    }

    public createInstance(obj: {}) {
        Object.assign(this, obj);
    }
}
