import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LandingPageSectionAttributes} from '../../_models/landing-page-section-attributes';
import {InfobarService} from '../../_services/infobar.service';
import {SectionService} from '../../_services/section.service';
import {Subscription} from 'rxjs';
import {ScrollSpyService} from 'ng-spy';

@Component({
    selector: 'landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() attributes: LandingPageSectionAttributes;
    private subScrollto: Subscription = new Subscription();

    constructor(private infobarService: InfobarService, private sectionService: SectionService, private spyService: ScrollSpyService) {

    }

    ngAfterViewInit(): void {
        this.spyService.spy({thresholdTop: 300, thresholdBottom: 400});
    }

    ngOnInit(): void {
    }

    scrollToNextSection() {
        this.infobarService.setScrollTo(this.sectionService.getSections()[1].navConfig.id, -72);
    }

    ngOnDestroy(): void {
        this.subScrollto.unsubscribe();
    }
}
