import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FeatureSectionAttributes} from '../../_models/feature-section-attributes';
import {ScrollSpyService} from 'ng-spy';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'feature-section',
    templateUrl: './feature-section.component.html',
    styleUrls: ['./feature-section.component.scss']
})
export class FeatureSectionComponent implements OnInit, AfterViewInit {
    attributes: FeatureSectionAttributes;

    constructor(private spyService: ScrollSpyService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.spyService.spy({thresholdTop: 300, thresholdBottom: 400});
    }
}
