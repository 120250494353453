import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver, HostListener, OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {Address} from '../../_models/address';
import {Person} from '../../_models/person';
import {Company} from '../../_models/company';
import {OwnerInfoService} from '../../_services/owner-info.service';
import {SectionService} from '../../_services/section.service';
import {Section} from '../../_models/section';
import {InfobarService} from '../../_services/infobar.service';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ScrollSpyService} from 'ng-spy';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'section-wrapper',
    templateUrl: './section-wrapper.component.html',
    styleUrls: ['./section-wrapper.component.scss']
})
export class SectionWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('sectionsContainer', {read: ViewContainerRef}) entry: ViewContainerRef;

    private sections: Array<Section> = [];
    private currentSection: string;
    private currentRoute: string;
    private NAV_TRACK_SCROLL_TIMEOUT = 350;

    address: Address;
    company: Company;
    person: Person;
    private routerSubscription: Subscription = new Subscription();
    private subCurrentRoute: Subscription = new Subscription();
    private subScrollTo: Subscription = new Subscription();


    constructor(private ownerInfoService: OwnerInfoService, private resolver: ComponentFactoryResolver, private cdr: ChangeDetectorRef,
                private sectionService: SectionService, private infobarService: InfobarService, private router: Router, private spyService: ScrollSpyService) {
        this.sections = this.sectionService.getSections();
        this.address = this.ownerInfoService.address;
        this.person = this.ownerInfoService.person;
        this.company = this.ownerInfoService.company;

        this.infobarService.getCurrentSection().subscribe((section) => {
            this.currentSection = section;
        });

        this.subCurrentRoute = this.infobarService.getCurrentRoute().subscribe((route) => {
            this.currentRoute = route;
        });

        this.subScrollTo = this.infobarService.getScrollTo().subscribe(({section, offset}) => {
            this.scrollToSection(section, offset);
        });

        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            if (event.url === '/') {
                this.infobarService.setCurrentRoute(event.url.substr(1));
                setTimeout(() => {
                    this.scrollToSection(this.infobarService.currentSection.getValue());
                }, this.NAV_TRACK_SCROLL_TIMEOUT);
            }
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
        this.subCurrentRoute.unsubscribe();
        this.subScrollTo.unsubscribe();
    }

    @HostListener('window:scroll', ['$event'])
    scrollHandler() {
        if (document.documentElement.scrollTop < 10) {
            this.infobarService.setIsTop(true);
        } else {
            this.infobarService.setIsTop(false);
        }
        // setTimeout(() => {
        //     this.detectSectionInViewport();
        // }, this.NAV_TRACK_SCROLL_TIMEOUT);
    }

    /**
     * Detects, which section is actually in viewport
     */
    detectSectionInViewport() {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.sections.length; i++) {
            const element = document.getElementById(this.sections[i].navConfig.id);
            if (!element) {
                return;
            }

            const rect = element.getBoundingClientRect();

            const r = (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );

            if (r === true && this.sections[i].navConfig.id !== this.currentSection) {
                this.infobarService.setCurrentSection(this.sections[i].navConfig.id);
            }
        }

    }

    ngAfterViewInit(): void {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.sections.length; i++) {
            this.createComponent(this.sections[i].component, this.sections[i].attributes);
        }

        this.spyService.activeSpyTarget.subscribe((activeTargetName: string) => {
            this.infobarService.setCurrentSection(activeTargetName);
        });
    }

    /**
     * Create dynamically component instance with attributes
     * @param component to load
     * @param attributes of the component
     */
    createComponent(component: any, attributes: any) {
        const factory = this.resolver.resolveComponentFactory(component);
        const componentRef = this.entry.createComponent(factory);
        // @ts-ignore
        componentRef.instance.attributes = attributes;
        this.cdr.detectChanges();
    }

    ngOnInit(): void {

    }

    /**
     * scroll to section with id
     * @param currentSection: id of current section
     * @param offset is optional and can be useful to fit the scroll depth
     */
    private scrollToSection(currentSection: string, offset?: number) {
        if (this.currentRoute === '') {
            const id = currentSection;
            const yOffset = offset || -72;
            const element = document.getElementById(id);
            if (!element) {
                return;
            }

            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }
}
