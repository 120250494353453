import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CarouselAttributes} from '../../_models/carousel-attributes';
import lozad from 'lozad';
import {ScrollSpyService} from 'ng-spy';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'carousel-section',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CarouselComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('wrapper', {read: ElementRef}) wrapper: ElementRef;
    @Input() attributes: CarouselAttributes;
    carouselHeight = 0;
    private subConfig: Subscription = new Subscription();

    constructor(private spyService: ScrollSpyService, private http: HttpClient) {
    }

    ngOnInit(): void {
        this.subConfig = this.http.get('assets/config.json').subscribe((conf: any) => {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < conf.sections.length; i++) {
                if (conf.sections[i].name === 'Carousel') {
                    this.attributes.images = conf.sections[i].attributes.images;
                    break;
                }
            }
        });
    }

    ngAfterViewInit(): void {
        const observer = lozad();
        observer.observe();

        this.carouselHeight = this.wrapper.nativeElement.offsetHeight - 160;
        this.spyService.spy({thresholdTop: 300, thresholdBottom: 400});
    }

    ngOnDestroy(): void {
        this.subConfig.unsubscribe();
    }

}
