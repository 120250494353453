import {Component, Input, OnInit} from '@angular/core';
import {CustomHtmlAttributes} from '../../_models/custom-html-attributes';

@Component({
  selector: 'app-custom-html',
  templateUrl: './custom-html.component.html',
  styleUrls: ['./custom-html.component.scss']
})
export class CustomHtmlComponent implements OnInit {
  @Input() attributes: CustomHtmlAttributes;

  constructor() { }

  ngOnInit(): void {
  }

}
